/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

import "./layout.css";

import FreeW from "../images/first-btn.inline.svg";

const CustomFooter = ({ children, htmllang }) => {
  var currentLang = htmllang;
  if (currentLang === "en-us") {
    return (
      <>
        <footer className="removemobile">
          {/* <div className="footerlogo">
            <Link to="/">
              <DagS />
            </Link>
          </div> */}
          <div className="footerwrapper">
            <div className="footer-menu">
              <ul>
                {/* <li><a href="#">Bitcoin compared to Dagcoin</a></li> */}
                <li>
                  <a href="https://www.dagpay.io/">Dagpay for Business</a>
                </li>
                <li>
                  <a href="https://wiki.dagcoin.org/">Documentation</a>
                </li>
                <li>
                  <Link to="/blog/">Cryptonews</Link>
                </li>
                <li>
                  <Link to="/blog/">Dagnews</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact us</Link>
                </li>
                {/* <li><a href="#">Newsletter</a></li> */}
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/tos/">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/ecosystem-timeline/">Ecosystem timeline</Link>
                </li>
                <li>
                  <Link to="/timeline/">Timeline</Link>
                </li>
              </ul>
            </div>
            <div className="footer-cta">
              <ul className="ctabtns">
                <li>
                  <Link to="/dagcoin-wallet/">
                    <FreeW />
                    Download wallet
                  </Link>
                </li>
                {/* <li>
                  <Link to="/getting-dagcoins/">
                    <GetD />
                    Get dagcoins
                  </Link>
                </li> */}
              </ul>

              <div className="socialmedia">
                <a
                  className="aio-tooltip"
                  href="https://www.facebook.com/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-facebook-official"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://twitter.com/dagcoin"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-twitter-square"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.instagram.com/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-instagram"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.youtube.com/channel/UChNN88DpmBNZUmkDhjs81NQ"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-youtube-play"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.linkedin.com/company/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-linkedin"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  } else if (currentLang === "et-ee") {
    return (
      <>
        <footer className="removemobile">
          {/* <div className="footerlogo">
            <Link to="/et">
              <DagS />
            </Link>
          </div> */}
          <div className="footerwrapper">
            <div className="footer-menu">
              <ul>
                {/* <li><a href="#">Bitcoin compared to Dagcoin</a></li> */}
                <li>
                  <a href="https://www.dagpay.io/">Dagpay ettevõtetele</a>
                </li>
                <li>
                  <a href="https://prismic-io.s3.amazonaws.com/dagcoin/f4e531e1-a5db-43b6-930c-14bf705e65ee_Dagcoin_White_Paper.pdf">
                    White paper
                  </a>
                </li>
                <li>
                  <Link to="/et/kontakt/">Võta meiega ühendust</Link>
                </li>
                <li>
                  <Link to="/et/blogi/">Krüptouudised</Link>
                </li>
                <li>
                  <Link to="/et/blogi/">Uudised</Link>
                </li>
                <li>
                  <Link to="/et/okosusteemi-ajatelg/">Ökosüsteemi ajatelg</Link>
                </li>
                <li>
                  <Link to="/et/tehniline-ajatelg/">Tehniline ajatelg</Link>
                </li>
                <li>
                  <Link to="/et/privaatsuspoliitika/">
                    Privaatsustingimused
                  </Link>
                </li>
                <li>
                  <Link to="/et/tos/">Kasutajatingimused</Link>
                </li>
              </ul>
            </div>
            <div className="footer-cta">
              <ul className="ctabtns">
                <li>
                  <Link to="/et/rahakott/">
                    <FreeW />
                    Laadi alla DagWallet
                  </Link>
                </li>
                {/* <li>
                  <Link to="/et/soeta-dagcoine/">
                    <GetD />
                    Soeta dagcoin’e
                  </Link>
                </li> */}
              </ul>

              <div className="socialmedia">
                <a
                  className="aio-tooltip"
                  href="https://www.facebook.com/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-facebook-official"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://twitter.com/dagcoin"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-twitter-square"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.instagram.com/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-instagram"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.youtube.com/channel/UChNN88DpmBNZUmkDhjs81NQ"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-youtube-play"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.linkedin.com/company/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-linkedin"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  } else {
    return (
      <>
        <footer className="removemobile">
          {/* <div className="footerlogo">
            <Link to="/">
              <DagS />
            </Link>
          </div> */}
          <div className="footerwrapper">
            <div className="footer-menu">
              <ul>
                {/* <li><a href="#">Bitcoin compared to Dagcoin</a></li> */}
                <li>
                  <a href="https://www.dagpay.io/">Dagpay for Business</a>
                </li>
                <li>
                  <a href="https://wiki.dagcoin.org/">Documentation</a>
                </li>
                <li>
                  <Link to="/blog/">Dagnews</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact us</Link>
                </li>
                {/* <li><a href="#">Newsletter</a></li> */}
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/tos/">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/ecosystem-timeline/">Ecosystem timeline</Link>
                </li>
                <li>
                  <Link to="/timeline/">Timeline</Link>
                </li>
              </ul>
            </div>
            <div className="footer-cta">
              <ul className="ctabtns">
                <li>
                  <Link to="/dagcoin-wallet/">
                    <FreeW />
                    Download wallet
                  </Link>
                </li>
                {/* <li>
                  <Link to="/getting-dagcoins/">
                    <GetD />
                    Get dagcoins
                  </Link>
                </li> */}
              </ul>

              <div className="socialmedia">
                <a
                  className="aio-tooltip"
                  href="https://www.facebook.com/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-facebook-official"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://twitter.com/dagcoin"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-twitter-square"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.instagram.com/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-instagram"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.youtube.com/channel/UChNN88DpmBNZUmkDhjs81NQ"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  data-title=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-youtube-play"></i>
                  </div>
                </a>
                <a
                  className="aio-tooltip"
                  href="https://www.linkedin.com/company/dagcoin/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement=""
                  title=""
                >
                  <div
                    className="aio-icon"
                    data-animation=""
                    data-animation-delay="03"
                  >
                    <i className="Defaults-linkedin"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
};

CustomFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomFooter;
